import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MaterialReactTable from 'material-react-table';
import { getSettings, createSetting, updateSetting, deleteSetting } from '../../_action/site.action';
import { AlertDialog } from '../main/main';

const getUniqueComponentValues = (data) => {
    const uniqueComponents = new Set(); // Create a Set to store unique values

    for (const obj of data) {
        if (obj.hasOwnProperty('Component')) {
            const componentValue = obj.Component;
            uniqueComponents.add(componentValue);
        }
    }

    return [...uniqueComponents]; // Convert the Set back to an array
};

const EditSettingDialog = (props) => {
    const { editOpen, mode, target, handleFormChange, handleEditClose, data } = props;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={editOpen}
        >
            <DialogTitle>
                {mode} Setting
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleEditClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                label='Component'
                                required
                                select
                                input={
                                    <OutlinedInput
                                        id='select-multiple-chip'
                                        label='Chip'
                                    />
                                }
                                MenuProps={MenuProps}
                                value={target.Component}
                                onChange={(e) => handleFormChange('Component', e)}
                            >
                                {getUniqueComponentValues(data).map((component) => (
                                    <MenuItem
                                        key={component}
                                        value={component}
                                    >
                                        <ListItemText primary={component} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-keyword'
                                label='Keyword'
                                required
                                value={target.Keyword}
                                onChange={(e) => handleFormChange('Keyword', e)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-value'
                                required
                                label='Value'
                                value={target.Value}
                                onChange={(e) => handleFormChange('Value', e)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-comment'
                                value={target.Comment}
                                label='Comment (Optional)'
                                onChange={(e) => handleFormChange('Comment', e)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={target.IsActive}
                                        onChange={(e) => {
                                            return handleFormChange('IsActive', e.target.checked);
                                        }}
                                    />
                                }
                                label='Is Active'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {mode === 'Create' ? (
                    <Button
                        onClick={() => handleEditClose('SAVE')}
                        disabled={!target.Keyword || !target.Value || !target.Component}
                        variant='contained'
                        sx={{ marginBottom: '10px', marginRight: '10px' }}
                    >
                        Create setting
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => handleEditClose('CANCEL')}>Cancel</Button>
                        <Button
                            onClick={() => handleEditClose('SAVE')}
                            disabled={!target.Keyword || !target.Value || !target.Component}
                        >
                            Save
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

const DeleteSettingDialog = (props) => {
    const { deleteOpen, target, handleDeleteClose } = props;
    const [value, setValue] = useState('');
    const disableDelete = value !== target.Keyword;
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteOpen}
        >
            <DialogTitle>Delete Setting?</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Alert
                            severity='warning'
                            sx={{ fontSize: '16px', padding: '6px' }}
                        >
                            This will permanently delete the setting. You cannot undo this action.
                        </Alert>
                        <br />
                        <Typography>
                            Enter keyword "<b>{target.Keyword}</b>" to confirm.
                        </Typography>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDeleteClose('CANCEL');
                        setValue('');
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteClose('CONFIRM');
                        setValue('');
                    }}
                    variant='contained'
                    sx={{ backgroundColor: '#C62828', color: '#FFFFFF' }}
                    disabled={disableDelete}
                >
                    DELETE SETTING
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Setting = () => {
    const [data, setData] = useState([]);

    const [mode, setMode] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState(null);
    const [alertContent, setAlertContent] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const settingClass = {
        Keyword: '',
        Value: '',
        Comment: '',
        Component: '',
        IsActive: false,
    };

    const [target, setTarget] = useState(settingClass);

    const [loading, setLoading] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                header: 'Component',
                accessorKey: 'Component',
                size: 200,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            { header: 'Keyword', accessorKey: 'Keyword', size: 200 },
            { header: 'Value', accessorKey: 'Value', size: 200 },
            {
                header: 'Active',
                accessorKey: 'IsActive',
                size: 50,
                Cell: ({ cell, row }) => (row.original.IsActive ? <CheckIcon /> : <CloseIcon />),
            },
            { header: 'Comment', accessorKey: 'Comment' },
            {
                header: '',
                accessorKey: 'actions',
                size: 50,
                enableHiding: false,
                enableColumnFilter: false,
                muiTableBodyCellProps: { align: 'center' },
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <IconButton
                            title={'Edit Setting'}
                            onClick={() => handleEdit(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <EditIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton
                            title={'Delete Setting'}
                            onClick={() => handleDelete(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <DeleteIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </>
                ),
            },
        ],
        [],
    );

    React.useEffect(() => {
        refreshSettingTable();
    }, []);

    const refreshSettingTable = () => {
        setLoading(true);
        getSettings().then(
            (res) => {
                setData(res.data);
                setLoading(false);
            },
            () => {
                setData([]);
                setLoading(false);
            },
        );
    };

    const handleCreate = () => {
        setMode('Create');
        setTarget(settingClass);
        setEditOpen(true);
    };

    const handleEdit = (data) => {
        setMode('Edit');
        setTarget(data);
        setEditOpen(true);
    };

    const handleDelete = (data) => {
        setTarget(data);
        setDeleteOpen(true);
    };

    const handleFormChange = (key, e) => {
        let t = { ...target };
        if (key === 'IsActive') {
            t[key] = e;
        } else {
            t[key] = e.target.value.trimStart();
        }
        setTarget(t);
    };

    const handleEditClose = (option) => {
        if (option === 'SAVE') {
            if (mode === 'Create') {
                createSetting(target).then(
                    (res) => {
                        setMode(null);
                        setEditOpen(false);
                        setTarget(settingClass);
                        refreshSettingTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data.Message) {
                            message = err.response.data.Message;
                        }
                        setAlertContent(message);
                    },
                );
            } else {
                updateSetting(target.Id, target).then(
                    (res) => {
                        setMode(null);
                        setEditOpen(false);
                        setTarget(settingClass);
                        refreshSettingTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data.Message) {
                            message = err.response.data.Message;
                        }
                        setAlertContent(message);
                    },
                );
            }
        } else {
            setMode(null);
            setTarget(settingClass);
            setEditOpen(false);
        }
    };

    const handleDeleteClose = (option) => {
        if (option === 'CONFIRM') {
            deleteSetting(target.Id).then(
                (res) => {
                    setMode(null);
                    setDeleteOpen(false);
                    setTarget(settingClass);
                    refreshSettingTable();
                },
                (err) => {
                    setAlertOpen(true);
                    setAlertTitle('Error!');
                    let message = '';
                    if (err.response.data.Message) {
                        message = err.response.data.Message;
                    }
                    setAlertContent(message);
                },
            );
        } else {
            setTarget(settingClass);
            setDeleteOpen(false);
        }
    };

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle(null);
        setAlertContent(null);
    };

    const trimInput = (key, e) => {
        let t = { ...target };
        t[key] = e.target.value.trim();
        setTarget(t);
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ margin: '15px' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableDensityToggle={false}
                        positionPagination='both'
                        enableFullScreenToggle={false}
                        enableTopToolbar={true}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 15],
                        }}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            pagination: { pageSize: 15 },
                            sorting: [{ id: 'Keyword', desc: true }],
                        }}
                        state={{ isLoading: loading }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => refreshSettingTable()}
                                    title={'Refresh'}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton
                                    variant='outlined'
                                    title={'Create New Setting'}
                                    onClick={() => handleCreate()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                </Grid>
            </Grid>
            <EditSettingDialog
                mode={mode}
                target={target}
                editOpen={editOpen}
                handleFormChange={handleFormChange}
                handleEditClose={handleEditClose}
                onBlur={trimInput}
                data={data}
            />
            <DeleteSettingDialog
                deleteOpen={deleteOpen}
                target={target}
                handleDeleteClose={handleDeleteClose}
            />
            <AlertDialog
                alertOpen={alertOpen}
                alertTitle={alertTitle}
                alertContent={alertContent}
                handleAlertDialogClose={handleAlertDialogClose}
            />
        </>
    );
};

export default Setting;
