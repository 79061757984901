import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from '../login/login';
import { Main } from '../main/main';
import Package from '../package/package';
import Account from '../account/account.jsx';
import Setting from '../setting/setting';
import Vintage from '../vintage/vintage';
import Admin from '../admin/admin';

import ProtectedRoutes from './protectedRoutes';
import PublicRoutes from './publicRoutes';
import InnerContent from './innerContent';

const MainRoutes = (props) => (
    <Routes>
        <Route
            path='/'
            element={<ProtectedRoutes />}
        >
            <Route
                path='/'
                element={<Main props={sessionStorage.getItem('userInfo')} />}
            >
                <Route
                    path='/'
                    element={
                        <Navigate
                            replace
                            to='packages'
                        />
                    }
                />
                <Route
                    path='packages'
                    element={<Package />}
                />
                <Route
                    path='accounts'
                    element={<Account />}
                />
                <Route
                    path='settings'
                    element={<Setting />}
                />
                <Route
                    path='vintages'
                    element={<Vintage />}
                />
                <Route
                    path='admins'
                    element={<Admin />}
                />
            </Route>
        </Route>

        <Route
            path='login'
            element={<PublicRoutes />}
        >
            <Route
                path='/login'
                element={<Login />}
            />
        </Route>
    </Routes>
);

export default MainRoutes;
