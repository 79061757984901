import { axiosDefault } from '../_config/authHeader';
import { config } from '../_config/config';
import { convertArrayToString } from '../_helpers/utilities';

export const getPackages = () => {
    const url = config.DataCloudApi + 'datapackages';
    return axiosDefault.get(url);
};

export const getSystemInfo = () => {
    const url = config.DataCloudApi + 'systemInfo';
    return axiosDefault.get(url);
};

export const getStatus = () => {
    const url = config.DataCloudApi + 'datapackages/statuses';
    return axiosDefault.get(url);
};

export const getAccounts = () => {
    const url = config.DataCloudApi + 'accounts';
    return axiosDefault.get(url);
};

export const getCountries = () => {
    const url = config.DataCloudApi + 'isocountrycodes';
    return axiosDefault.get(url);
};

export const createAccount = (data) => {
    const url = config.DataCloudApi + 'accounts';
    return axiosDefault.post(url, data);
};

export const updateAccount = (id, data) => {
    const url = config.DataCloudApi + 'accounts/' + id;
    return axiosDefault.put(url, data);
};

export const deleteAccount = (id) => {
    const url = config.DataCloudApi + 'accounts/' + id;
    return axiosDefault.delete(url);
};

export const getAllAccountRoles = (data) => {
    const url = config.DataCloudApi + 'accounts/listallaccountroles';
    return axiosDefault.get(url, data);
};

export const getPurchaseHistory = (id) => {
    const url = config.DataCloudApi + 'vintagepurchasehistory?accountid=' + id;
    return axiosDefault.get(url);
};

export const createPurchaseHistory = (data) => {
    const url = config.DataCloudApi + 'vintagepurchasehistory';
    return axiosDefault.post(url, data);
};

export const updatePurchaseHistory = (id, data) => {
    const url = config.DataCloudApi + 'vintagepurchasehistory/' + id;
    return axiosDefault.put(url, data);
};

export const deletePurchaseHistory = (id) => {
    const url = config.DataCloudApi + 'vintagepurchasehistory/' + id;
    return axiosDefault.delete(url);
};

export const getSettings = () => {
    const url = config.DataCloudApi + 'settings';
    return axiosDefault.get(url);
};

export const createSetting = (data) => {
    const url = config.DataCloudApi + 'settings';
    return axiosDefault.post(url, data);
};

export const updateSetting = (id, data) => {
    const url = config.DataCloudApi + 'settings/' + id;
    return axiosDefault.put(url, data);
};

export const deleteSetting = (id) => {
    const url = config.DataCloudApi + 'settings/' + id;
    return axiosDefault.delete(url);
};

export const getVintages = (isPurchasable) => {
    let url = config.DataCloudApi + 'vintages';
    if (isPurchasable) url += '?isPurchasable=true';
    return axiosDefault.get(url);
};

export const checkVintage = (data) => {
    const url =
        config.DataCloudApi +
        `vintages/doesexist?Vendor=${data.Vendor}&Version=${data.Version}&Frequency=${data.Frequency}&FrequencyVersion=${data.FrequencyVersion}`;
    return axiosDefault.get(url);
};

export const createVintage = (data) => {
    const url = config.DataCloudApi + 'vintages';
    return axiosDefault.post(url, data);
};

export const updateVintage = (id, data) => {
    const url = config.DataCloudApi + 'vintages/' + id;
    return axiosDefault.put(url, data);
};

export const deleteVintages = (arrOfId) => {
    const ids = convertArrayToString(arrOfId);
    const url = config.DataCloudApi + `vintages?${ids}`;
    return axiosDefault.delete(url);
};

export const getAdmins = () => {
    const url = config.DataCloudApi + 'admins';
    return axiosDefault.get(url);
};

export const createAdmin = (data) => {
    data.role = 0;
    const url = config.DataCloudApi + 'admins';
    return axiosDefault.post(url, data);
};

export const deleteAdmin = (id) => {
    const url = config.DataCloudApi + 'admins/' + id;
    return axiosDefault.delete(url);
};
