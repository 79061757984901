import axios from 'axios';
import { axiosDefault } from '../_config/authHeader';
import { config } from '../_config/config';

export const userLogin = (userName, password) => {
    const url = config.DataCloudApi + 'token';
    const data = {
        grant_type: 'password',
        user_type: 'admin',
        userName: userName,
        password: password,
    };
    return axios.post(url, new URLSearchParams(data).toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
};

export const getSystemInfo = () => {
    const url = config.DataCloudApi + 'systemInfo';
    return axiosDefault.get(url);
};
