import { parseISO, format } from 'date-fns';

export const getUserLocalDateTime = (cellValue, unit) => {
    const formatToUTC = `${format(parseISO(cellValue), unit)} UTC`;
    return format(new Date(formatToUTC), unit);
};

export const formatISOToDateTimeUnit = (isoString, outputFormat) => {
    return format(parseISO(isoString), outputFormat);
};

export const generateRandomPassword = (length) => {
    const chars = 'abcdefghjkmnpqrstuvwxyz@#$%ABCDEFGHJKLMNP23456789';
    let pass = '';
    for (let x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
};
export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

// export const isValidClientId = clientId =>  /^\S*$/.test(clientId)

export const validateClientId = (clientId, setError) => {
    // Regular expression pattern to match alphanumeric characters and underscore
    const pattern = /^[a-zA-Z0-9_]+$/;
    if (clientId.length) {
        // Check if the username contains a space
        if (clientId.includes(' ')) {
            setError('Client ID cannot contain spaces');
            return false; // Username contains a space, which is not allowed
        }

        // Check if the username's length is within the allowed range (3 to 20 characters)
        if (clientId.length > 20 || clientId.length < 3) {
            setError('Client ID must be between 3-20 characters');
            return false; // Username length is invalid
        }

        // Test the input against the pattern
        if (pattern.test(clientId)) {
            setError(null);
            return true; // Username is valid
        } else {
            setError('Client ID can only contain alphanumeric characters and underscores');
            return false; // Username is invalid
        }
    }
};

export const validateCustomVintagePrefix = (customVintagePrefix, setError) => {
    const pattern = /^[a-zA-Z0-9_]+$/;
    if (customVintagePrefix.length) {
        if (!/^[a-zA-Z_]/.test(customVintagePrefix.charAt(0))) {
            setError('Prefix cannot start with a number');
            return false;
        }
        if (customVintagePrefix.includes(' ')) {
            setError('Prefix cannot contain spaces');
            return false;
        }

        if (customVintagePrefix.length > 15 || customVintagePrefix.length < 3) {
            setError('Prefix must be between 3-15 characters');
            return false;
        }

        if (pattern.test(customVintagePrefix)) {
            setError(null);
            return true;
        } else {
            setError('Prefix can only contain alphanumeric characters and underscores');
            return false;
        }
    }
};

export const validateAndHandleFormChange = (target, event, key, setError) => {
    const value = key === 'CustomVintagePrefix' ? event : event.target.value;
    if (key === 'ClientId') {
        validateClientId(value, setError);
    } else if (key === 'EmailAddress') {
        if (value.length && !isValidEmail(value)) {
            setError('Email is invalid');
        } else {
            setError(null);
        }
    } else if (key === 'CustomVintagePrefix') {
        validateCustomVintagePrefix(value, setError);
    }

    target[key] = value.trim();
};

export const hasSelection = (obj) => Object.keys(obj).length > 0;

export const convertArrayToString = (arr) => {
    return arr.map((item) => `id=${item}`).join('&');
};
