import * as React from 'react';
import { CssBaseline, Button, TextField, Link, Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import siteLogo from '../../_asset/logo.png';
import { userLogin } from '../../_action/login.action';
import { useNavigate } from 'react-router-dom';
import { AlertDialog } from '../main/main';

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link
                color='inherit'
                href='https://wwww.routesmart.com/'
            >
                RouteSmart Technologies, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const LogIn = () => {
    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState(null);
    const [alertContent, setAlertContent] = React.useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const userName = data.get('userName');
        const password = data.get('password');

        userLogin(userName, password).then(
            (res) => {
                const userInfo = {
                    accessToken: res.data.access_token,
                    userName: userName,
                    userRole: res.data.api_role,
                    FirstName: res.data.display_name,
                };
                sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                navigate('/packages');
            },
            (err) => {
                setAlertOpen(true);
                setAlertTitle('Error!');
                let message = 'Login Failed! Please try again later.';
                if (err.response.data) {
                    message = err.response.data;
                }
                setAlertContent(message);
            },
        );
    };

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle(null);
        setAlertContent(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                component='main'
                maxWidth='xs'
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component='img'
                        sx={{
                            height: 53,
                            width: 153,
                        }}
                        alt='RouteSmart Logo'
                        src={siteLogo}
                    />
                    <Box
                        component='form'
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='userName'
                            label='User Name'
                            name='userName'
                            autoComplete='userName'
                            autoFocus
                        />
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            autoComplete='current-password'
                        />
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 2, mb: 4 }} />
                <AlertDialog
                    alertOpen={alertOpen}
                    alertTitle={alertTitle}
                    alertContent={alertContent}
                    handleAlertDialogClose={handleAlertDialogClose}
                />
            </Container>
        </ThemeProvider>
    );
};

export default LogIn;
