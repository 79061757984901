import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MaterialReactTable from 'material-react-table';
import Select from 'react-select';
import { getVintages, checkVintage, createVintage, updateVintage, deleteVintages } from '../../_action/site.action';
import { ConfirmDialog, AlertDialog } from '../main/main';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import UnCheckIcon from '@mui/icons-material/Close';
import { hasSelection } from '../../_helpers/utilities';

const EditVintageDialog = (props) => {
    const { editOpen, mode, target, vintageOptions, handleFormChange, handleEditClose, onBlur } = props;

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={editOpen}
        >
            <DialogTitle>{mode} Vintage</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '0.5rem 0' }}
                            >
                                Vendor
                            </Typography>
                            <OutlinedInput
                                id='outlined-adornment-vendor'
                                required
                                disabled={mode === 'Edit'}
                                value={target.Vendor}
                                onChange={(e) => handleFormChange('Vendor', e)}
                                sx={{ height: '40px' }}
                                onBlur={(e) => onBlur('Vendor', e)}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '0.5rem 0' }}
                            >
                                Year
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={mode === 'Edit'}
                                name='Version'
                                value={vintageOptions.find((v) => v.value === target.Version)}
                                onChange={(e) => handleFormChange('Version', e)}
                                options={vintageOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '0.5rem 0' }}
                            >
                                Release Prefix
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={mode === 'Edit'}
                                name='Frequency'
                                value={{ label: target.Frequency, value: target.Frequency }}
                                onChange={(e) => handleFormChange('Frequency', e)}
                                options={[
                                    { label: 'Q', value: 'Q' },
                                    { label: 'V', value: 'V' },
                                ]}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '0.5rem 0' }}
                            >
                                Release Version
                            </Typography>
                            <OutlinedInput
                                id='outlined-adornment-frequencyVersion'
                                value={target.FrequencyVersion}
                                disabled={mode === 'Edit'}
                                onChange={(e) => handleFormChange('FrequencyVersion', e)}
                                sx={{ height: '40px' }}
                                onBlur={(e) => onBlur('FrequencyVersion', e)}
                            />
                        </FormControl>
                    </Grid>
                    {mode === 'Edit' && (
                        <Grid
                            item
                            xs={12}
                            sx={{ mt: '10px' }}
                        >
                            <FormControlLabel
                                value='IsAvailable'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={target.IsAvailable}
                                        onChange={(e) => handleFormChange('IsAvailable', e)}
                                    />
                                }
                                label='Available'
                                labelPlacement='start'
                            />
                            <FormControlLabel
                                value='IsPurchasable'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={target.IsPurchasable}
                                        onChange={(e) => handleFormChange('IsPurchasable', e)}
                                    />
                                }
                                label='Purchasable'
                                labelPlacement='start'
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleEditClose('CANCEL')}>Cancel</Button>
                <Button
                    onClick={() => handleEditClose('SAVE')}
                    disabled={!target.Vendor || !target.FrequencyVersion}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteVintageDialog = (props) => {
    const { deleteOpen, handleDeleteClose, isMultiDelete, selectedVintageIdsLen } = props;
    const [value, setValue] = useState('');
    const disableDelete = isMultiDelete ? value !== `DELETE ${selectedVintageIdsLen} VINTAGES` : value !== `DELETE 1 VINTAGE`;

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteOpen}
        >
            <DialogTitle>{isMultiDelete ? 'Delete Vintages?' : 'Delete Vintage?'}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Alert
                            severity='warning'
                            sx={{ fontSize: '16px', padding: '6px' }}
                        >{`This will permanently delete the ${isMultiDelete ? 'records' : 'vintage'}. You cannot undo this action.`}</Alert>
                        <br />
                        <Typography>
                            Enter "<b>{isMultiDelete ? `DELETE ${selectedVintageIdsLen} VINTAGES` : `DELETE 1 VINTAGE`}</b>" to confirm.
                        </Typography>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDeleteClose('CANCEL');
                        setValue('');
                    }}
                >
                    CANCEL
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteClose('CONFIRM');
                        setValue('');
                    }}
                    variant='contained'
                    disabled={disableDelete}
                    sx={{ backgroundColor: '#C62828', color: '#FFFFFF' }}
                >
                    {isMultiDelete ? 'DELETE VINTAGES' : 'DELETE VINTAGE'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Vintage = () => {
    const [data, setData] = useState([]);

    const [mode, setMode] = useState(null);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState(null);
    const [confirmContent, setConfirmContent] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState(null);
    const [alertContent, setAlertContent] = useState(null);

    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const vintageClass = {
        Vendor: '',
        Version: new Date().getFullYear(),
        Frequency: 'Q',
        FrequencyVersion: '',
    };

    const [target, setTarget] = useState(vintageClass);
    const [vintageOptions, setVintageOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMultiDelete, setIsMultiDelete] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedVintageIds, setSelectedVintageIds] = useState(Object.keys(rowSelection).map((id) => parseInt(id)));

    useEffect(() => {
        setSelectedVintageIds(Object.keys(rowSelection).map((id) => parseInt(id)));
    }, [rowSelection]);

    const columns = React.useMemo(
        () => [
            {
                header: '',
                accessorKey: 'Exists',
                size: 50,
                enableColumnActions: false,
                enableHiding: false,
                enableColumnFilter: false,
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) => {
                    if (!cell.getValue()) {
                        return (
                            <span
                                style={{
                                    padding: '0.2em 0.6em 0.3em',
                                    fontSize: '10px',
                                    backgroundColor: '#d9534f',
                                    color: 'white',
                                    fontWeight: 700,
                                }}
                            >
                                Data Does Not Exist
                            </span>
                        );
                    } else {
                        return null;
                    }
                },
            },
            { header: 'Vintage ID', accessorKey: 'Id', size: 50 },
            {
                header: 'Vendor',
                accessorKey: 'Vendor',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                header: 'Year',
                accessorKey: 'Version',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                header: 'Release Prefix',
                accessorKey: 'Frequency',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                header: 'Release Version',
                accessorKey: 'FrequencyVersion',
                size: 60,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                header: 'Available',
                accessorKey: 'IsAvailable',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) =>
                    cell.getValue() ? (
                        <CheckIcon sx={{ color: 'green', fontSize: '20px' }} />
                    ) : (
                        <UnCheckIcon sx={{ color: 'red', fontSize: '20px' }} />
                    ),
            },
            {
                header: 'Purchasable',
                accessorKey: 'IsPurchasable',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) =>
                    cell.getValue() ? (
                        <CheckIcon sx={{ color: 'green', fontSize: '20px' }} />
                    ) : (
                        <UnCheckIcon sx={{ color: 'red', fontSize: '20px' }} />
                    ),
            },
            {
                header: '',
                accessorKey: 'actions',
                size: 50,
                enableHiding: false,
                enableColumnFilter: false,
                muiTableBodyCellProps: { align: 'center' },
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <IconButton
                            title={'Edit Vintage'}
                            onClick={() => handleEdit(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <EditIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton
                            title={'Delete Vintage'}
                            onClick={() => handleDelete(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <DeleteIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </>
                ),
            },
        ],
        [],
    );

    useEffect(() => {
        refreshVintageTable();
        let vintageYears = [...Array(5).keys()];
        let currentYear = new Date().getFullYear();
        vintageYears = vintageYears.map((v, i) => {
            return {
                label: currentYear - i,
                value: currentYear - i,
            };
        });
        setVintageOptions(vintageYears);
    }, []);

    const refreshVintageTable = () => {
        setLoading(true);
        // TODO: clear out row selection when refresh
        setRowSelection({});
        getVintages().then(
            (res) => {
                setData(res.data);
                setLoading(false);
            },
            () => {
                setData([]);
                setLoading(false);
            },
        );
    };

    const handleCreate = () => {
        setMode('Create');
        setTarget(vintageClass);
        setEditOpen(true);
    };

    const handleEdit = (data) => {
        setMode('Edit');
        setTarget(data);
        setEditOpen(true);
    };

    const handleDelete = (data) => {
        setTarget(data);
        setDeleteOpen(true);
    };

    const handleFormChange = (key, e) => {
        let t = { ...target };
        if (key === 'Version' || key === 'Frequency') {
            if (e) {
                t[key] = e.value;
            } else {
                t[key] = null;
            }
        } else if (key === 'IsAvailable' || key === 'IsPurchasable') {
            t[key] = e.target.checked;
        } else {
            t[key] = e.target.value.trimStart();
        }
        setTarget(t);
    };

    const handleEditClose = (option) => {
        if (option === 'SAVE') {
            if (mode === 'Create') {
                checkVintage(target).then((res) => {
                    if (res.data === false) {
                        setConfirmOpen(true);
                        setConfirmTitle('Warning!');
                        const message =
                            'The vintage you are adding does not exist in the database. Click Proceed if you still wish to add this vintage, or Cancel to abort this action.';
                        setConfirmContent(message);
                    } else {
                        createVintage(target).then(
                            (res) => {
                                setMode(null);
                                setEditOpen(false);
                                setTarget(vintageClass);
                                refreshVintageTable();
                            },
                            (err) => {
                                setAlertOpen(true);
                                setAlertTitle('Error!');
                                let message = '';
                                if (err.response.data.Message) {
                                    message = err.response.data.Message;
                                }
                                setAlertContent(message);
                            },
                        );
                    }
                });
            } else {
                updateVintage(target.Id, target).then(
                    (res) => {
                        setMode(null);
                        setEditOpen(false);
                        setTarget(vintageClass);
                        refreshVintageTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data.Message) {
                            message = err.response.data.Message;
                        }
                        setAlertContent(message);
                    },
                );
            }
        } else {
            setMode(null);
            setTarget(vintageClass);
            setEditOpen(false);
        }
    };

    const handleConfirmDialogClose = (option) => {
        if (option === 'SAVE') {
            createVintage(target).then(
                (res) => {
                    setMode(null);
                    setEditOpen(false);
                    setTarget(vintageClass);
                    refreshVintageTable();
                },
                (err) => {
                    setAlertOpen(true);
                    setAlertTitle('Error!');
                    let message = '';
                    if (err.response.data.Message) {
                        message = err.response.data.Message;
                    }
                    setAlertContent(message);
                },
            );
        }
        setConfirmOpen(false);
        setConfirmTitle(null);
        setConfirmContent(null);
    };

    const handleDeleteClose = async (option) => {
        if (option === 'CONFIRM') {
            try {
                const data = selectedVintageIds.length ? selectedVintageIds : [target.Id];
                await deleteVintages(data).then((res) => {
                    setMode(null);
                    setDeleteOpen(false);
                    setTarget(vintageClass);
                    refreshVintageTable();
                    if (res.status === 207) {
                        const {
                            Statuses: [success, unsuccessful],
                        } = res.data;
                        setAlertOpen(true);
                        setAlertTitle('Status');
                        let message = '';
                        if (success.StatusCode === 200 && unsuccessful.StatusCode === 403) {
                            message = (
                                <ul>
                                    <li>{`${success.Description}: ${success.ResourceIds.join(', ')}`}</li>
                                    <li>{`${unsuccessful.Description.slice(0, -1)}: ${unsuccessful.ResourceIds.join(', ')}`}</li>
                                </ul>
                            );
                        }
                        setAlertContent(message);
                    }
                });
            } catch (error) {
                setAlertOpen(true);
                setAlertTitle('Error!');
                let message = '';
                if (error.response.data) {
                    message = error.response.data;
                }
                setAlertContent(message);
            } finally {
                setIsMultiDelete(false);
                setSelectedVintageIds([]);
                setDeleteOpen(false);
            }
        } else {
            setTarget(vintageClass);
            setDeleteOpen(false);
        }
    };

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle(null);
        setAlertContent(null);
    };

    const trimInput = (key, e) => {
        let t = { ...target };
        t[key] = e.target.value.trim();
        setTarget(t);
    };

    const deleteSingleVintage = () => {
        //TODO: might not need this setter here
        setIsMultiDelete(false);
        const id = Object.keys(rowSelection)[0];
        // eslint-disable-next-line eqeqeq
        const vintage = data.find((v) => v.Id == id);
        handleDelete(vintage);
    };

    const handleDeleteVintages = () => {
        setIsMultiDelete(true);
        const vintageIds = Object.keys(rowSelection).map((id) => parseInt(id));
        handleDelete(vintageIds);
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ margin: '15px' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        positionPagination='both'
                        enableRowSelection
                        getRowId={(row) => row.Id} //give each row a more useful id
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        enableTopToolbar={true}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 15],
                        }}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            pagination: { pageSize: 15 },
                            sorting: [
                                { id: 'Version', desc: true },
                                { id: 'FrequencyVersion', desc: true },
                            ],
                        }}
                        state={{ isLoading: loading, rowSelection }} //pass our managed row selection state to the table to use
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                {hasSelection(rowSelection) && (
                                    <IconButton
                                        variant='outlined'
                                        color='error'
                                        onClick={() => {
                                            if (Object.keys(rowSelection).length === 1) {
                                                deleteSingleVintage();
                                            } else {
                                                handleDeleteVintages();
                                            }
                                        }}
                                        title={'Delete selected account'}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    variant='outlined'
                                    onClick={() => refreshVintageTable()}
                                    title={'Refresh'}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton
                                    variant='outlined'
                                    title={'Create Vintage'}
                                    onClick={() => handleCreate()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                </Grid>
            </Grid>
            <EditVintageDialog
                mode={mode}
                target={target}
                editOpen={editOpen}
                vintageOptions={vintageOptions}
                handleFormChange={handleFormChange}
                handleEditClose={handleEditClose}
                onBlur={trimInput}
            />
            <DeleteVintageDialog
                deleteOpen={deleteOpen}
                handleDeleteClose={handleDeleteClose}
                isMultiDelete={isMultiDelete}
                selectedVintageIdsLen={selectedVintageIds.length}
            />
            <ConfirmDialog
                confirmOpen={confirmOpen}
                confirmTitle={confirmTitle}
                confirmContent={confirmContent}
                handleConfirmDialogClose={handleConfirmDialogClose}
            />
            <AlertDialog
                alertOpen={alertOpen}
                alertTitle={alertTitle}
                alertContent={alertContent}
                handleAlertDialogClose={handleAlertDialogClose}
            />
        </>
    );
};

export default Vintage;
