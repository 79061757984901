import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    let user;
    const _user = sessionStorage.getItem('userInfo');

    if (_user) {
        user = JSON.parse(_user);
    }
    if (user) {
        return {
            auth: true,
            token: user.accessToken,
        };
    } else {
        return {
            auth: false,
            token: null,
        };
    }
};

const ProtectedRoutes = (props) => {
    const { auth, token } = useAuth();
    return auth && token ? <Outlet /> : <Navigate to='/login' />;
};

export default ProtectedRoutes;
