import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    const user = sessionStorage.getItem('userInfo');
    return !!user;
};

const PublicRoutes = (props) => {
    const auth = useAuth();
    return auth ? <Navigate to='/' /> : <Outlet />;
};

export default PublicRoutes;
