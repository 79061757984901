import React, { useState } from 'react';
import {
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    OutlinedInput,
    Typography,
    Grid,
    IconButton,
    Button,
    TextField,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { getAdmins, createAdmin, deleteAdmin } from '../../_action/site.action';
import { AlertDialog } from '../main/main';
import { Refresh as RefreshIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { formatISOToDateTimeUnit } from '../../_helpers/utilities';

const EditAdminDialog = (props) => {
    const { editOpen, target, handleFormChange, handleEditClose } = props;

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={editOpen}
        >
            <DialogTitle>Add Admin User</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '0.5rem 0' }}
                            >
                                User ID
                            </Typography>
                            <OutlinedInput
                                id='outlined-adornment-keyword'
                                required
                                value={target.Username}
                                onChange={(e) => handleFormChange('Username', e)}
                                sx={{ height: '40px' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleEditClose('CANCEL')}>Cancel</Button>
                <Button
                    onClick={() => handleEditClose('SAVE')}
                    disabled={!target.Username}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteAdminDialog = (props) => {
    const { deleteOpen, target, handleDeleteClose } = props;
    const [value, setValue] = useState('');
    const disableDelete = value !== target.Username;

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteOpen}
        >
            <DialogTitle>Delete Admin?</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Alert
                            severity='warning'
                            sx={{ fontSize: '16px', padding: '6px' }}
                        >
                            {' '}
                            This will permanently delete the admin. You cannot undo this action.
                        </Alert>
                        <br />
                        <Typography>
                            Enter the user ID "<b>{target.Username}</b>" to confirm.
                        </Typography>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDeleteClose('CANCEL');
                        setValue('');
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteClose('CONFIRM');
                        setValue('');
                    }}
                    variant='contained'
                    sx={{ backgroundColor: '#C62828', color: '#FFFFFF' }}
                    disabled={disableDelete}
                >
                    DELETE ADMIN
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Admin = () => {
    const [data, setData] = useState([]);

    const [mode, setMode] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState(null);
    const [alertContent, setAlertContent] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const adminClass = { Username: '' };

    const [target, setTarget] = useState(adminClass);

    const [loading, setLoading] = useState(false);

    const columns = React.useMemo(
        () => [
            { header: 'User ID', accessorKey: 'Username', size: 150 },
            { header: 'First Name', accessorKey: 'FirstName', size: 150 },
            { header: 'Last Name', accessorKey: 'LastName', size: 150 },
            {
                header: 'Last Login',
                accessorKey: 'LastLogin',
                size: 100,
                Cell: ({ cell, row }) => {
                    return cell.getValue() ? formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm') : null;
                },
            },
            {
                header: 'Date Created',
                accessorKey: 'DateCreated',
                size: 100,
                Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
            },
            {
                header: 'Date Modified',
                accessorKey: 'DateModified',
                size: 100,
                Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
            },
            {
                header: '',
                accessorKey: 'actions',
                size: 50,
                enableHiding: false,
                enableColumnFilter: false,
                muiTableBodyCellProps: { align: 'center' },
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <IconButton
                            title={'Delete Admin User'}
                            onClick={() => handleDelete(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <DeleteIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </>
                ),
            },
        ],
        [],
    );

    React.useEffect(() => {
        refreshAdminTable();
    }, []);

    const refreshAdminTable = () => {
        setLoading(true);
        getAdmins().then(
            (res) => {
                setData(res.data);
                setLoading(false);
            },
            () => {
                setData([]);
                setLoading(false);
            },
        );
    };

    const handleCreate = () => {
        setMode('Create');
        setTarget(adminClass);
        setEditOpen(true);
    };

    const handleDelete = (data) => {
        setTarget(data);
        setDeleteOpen(true);
    };

    const handleFormChange = (key, e) => {
        let t = { ...target };
        t[key] = e.target.value.trim();
        setTarget(t);
    };

    const handleEditClose = (option) => {
        if (option === 'SAVE') {
            if (mode === 'Create') {
                createAdmin(target).then(
                    (res) => {
                        setMode(null);
                        setEditOpen(false);
                        setTarget(adminClass);
                        refreshAdminTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data.Message) {
                            message = err.response.data.Message;
                        }
                        setAlertContent(message);
                    },
                );
            }
        } else {
            setMode(null);
            setTarget(adminClass);
            setEditOpen(false);
        }
    };

    const handleDeleteClose = (option) => {
        if (option === 'CONFIRM') {
            deleteAdmin(target.Id).then(
                (res) => {
                    setMode(null);
                    setDeleteOpen(false);
                    setTarget(adminClass);
                    refreshAdminTable();
                },
                (err) => {
                    setAlertOpen(true);
                    setAlertTitle('Error!');
                    let message = '';
                    if (err.response.data.Message) {
                        message = err.response.data.Message;
                    }
                    setAlertContent(message);
                },
            );
        } else {
            setTarget(adminClass);
            setDeleteOpen(false);
        }
    };

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle(null);
        setAlertContent(null);
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
            >
                <Grid
                    item
                    xs={1}
                />
                <Grid
                    item
                    xs={10}
                    sx={{ margin: '15px' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        positionPagination='both'
                        enableTopToolbar={true}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 15],
                        }}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            pagination: { pageSize: 15 },
                            sorting: [{ id: 'Username', desc: false }],
                        }}
                        state={{ isLoading: loading }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => refreshAdminTable()}
                                    title={'Refresh'}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton
                                    variant='outlined'
                                    title={'Add Admin User'}
                                    onClick={() => handleCreate()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                </Grid>
            </Grid>
            <EditAdminDialog
                mode={mode}
                target={target}
                editOpen={editOpen}
                handleFormChange={handleFormChange}
                handleEditClose={handleEditClose}
            />
            <DeleteAdminDialog
                deleteOpen={deleteOpen}
                target={target}
                handleDeleteClose={handleDeleteClose}
            />
            <AlertDialog
                alertOpen={alertOpen}
                alertTitle={alertTitle}
                alertContent={alertContent}
                handleAlertDialogClose={handleAlertDialogClose}
            />
        </>
    );
};

export default Admin;
