import axios from 'axios';

export const axiosDefault = axios.create();
axiosDefault.defaults.timeout = 5 * 60 * 1000;

export function authHeader() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    let axiosheaders = {
        'Content-Type': 'application/json; charset=UTF-8',
    };
    if (userInfo) {
        return {
            ...axiosheaders,
            Authorization: 'Bearer ' + userInfo.accessToken,
        };
    } else {
        return {
            ...axiosheaders,
        };
    }
}

axiosDefault.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace(`${window.location.href}`);
        }
        return Promise.reject(error);
    },
);

axiosDefault.interceptors.request.use((config) => {
    config.headers = authHeader();
    return config;
});
