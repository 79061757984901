import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, IconButton, Button, Snackbar, Popover, Box } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { getPackages, getStatus } from '../../_action/site.action';
import { Refresh as RefreshIcon, FilterAlt as FilterAltIcon } from '@mui/icons-material';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatISOToDateTimeUnit } from '../../_helpers/utilities';
import { isAfter, parseISO, isBefore } from 'date-fns';

const CopyToClipboardButton = (props) => {
    const { textToCopy } = props;

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(textToCopy);
    };

    return (
        <>
            <Button
                sx={{ minWidth: 0 }}
                onClick={handleClick}
                variant='outlined'
            >
                copy
            </Button>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message='Copied to clipboard'
            />
        </>
    );
};

const BoundingBoxPopover = (props) => {
    const { value } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button
                aria-describedby={id}
                variant='text'
                onClick={handleClick}
            >
                {`${value.slice(0, 7)}...`}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { width: '30%' },
                }}
            >
                <Box
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <b>Bounding Box Value</b>
                    <CopyToClipboardButton textToCopy={value} />
                </Box>
                <Box sx={{ typography: 'body1' }}>
                    <Typography sx={{ p: 3, paddingTop: 0 }}>{value}</Typography>
                </Box>
            </Popover>
        </div>
    );
};

const PackageFilterDialog = (props) => {
    const {
        filterOpen,
        handleFilterClose,
        clientOptions,
        vintageOptions,
        clientFilter,
        setClientFilter,
        vintageFilter,
        setVintageFilter,
        statusOptions,
        statusFilter,
        setStatusFilter,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    } = props;

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={filterOpen}
        >
            <DialogTitle>Package Filter</DialogTitle>
            <DialogContent>
                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Client
                </Typography>
                <Select
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={true}
                    isSearchable={true}
                    name='Client'
                    value={clientFilter}
                    onChange={(e) => setClientFilter(e)}
                    options={clientOptions}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />

                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Status
                </Typography>
                <Select
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={true}
                    isSearchable={true}
                    name='Status'
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e)}
                    options={statusOptions}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />

                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Vintage
                </Typography>
                <Select
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={true}
                    isSearchable={true}
                    name='Vintage'
                    value={vintageFilter}
                    onChange={(e) => setVintageFilter(e)}
                    options={vintageOptions}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />

                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Start Date
                </Typography>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    isClearable
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='MM/dd/yyyy'
                />

                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    End Date
                </Typography>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    isClearable
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat='MM/dd/yyyy'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleFilterClose('RESET')}>Reset</Button>
                <Button onClick={() => handleFilterClose('SAVE')}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

const Package = () => {
    const [data, setData] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [clientOptions, setClientOptions] = React.useState([]);
    const [statusOptions, setStatusOptions] = React.useState([]);
    const [vintageOptions, setVintageOptions] = React.useState([]);
    const [clientFilter, setClientFilter] = React.useState(null);
    const [statusFilter, setStatusFilter] = React.useState(null);
    const [vintageFilter, setVintageFilter] = React.useState(null);

    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const memoizedColumns = [
        { accessorKey: 'PackageId', header: 'ID', size: 75 },
        {
            accessorKey: 'ClientId',
            header: 'Client',
            size: 75,
            muiTableHeadCellProps: { align: 'left' },
        },
        {
            accessorKey: 'DateRequestReceived',
            header: 'Request Date',
            size: 100,
            muiTableHeadCellProps: { align: 'left' },
            Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
        },
        {
            accessorKey: 'CreationEnd',
            header: 'Creation Date',
            size: 100,
            Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
        },
        {
            accessorKey: 'Label',
            header: 'Name',
            muiTableHeadCellProps: { align: 'center' },
        },
        { accessorKey: 'Status', header: 'Status', size: 80 },
        {
            accessorKey: 'Version',
            header: 'Vintage',
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            size: 90,
        },
        {
            accessorKey: 'PackageSize',
            header: 'Package Size (MB)',
            size: 120,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'center', sx: { paddingRight: '50px' } },
            Cell: ({ cell, row }) => cell.getValue().toFixed(2),
        },
        {
            accessorKey: 'BbHeight',
            header: 'Height (Miles)',
            size: 100,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'center', sx: { paddingRight: '50px' } },
            Cell: ({ cell, row }) => cell.getValue().toFixed(2),
        },
        {
            accessorKey: 'BbWidth',
            header: 'Width (Miles)',
            size: 90,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'center', sx: { paddingRight: '50px' } },
            Cell: ({ cell, row }) => cell.getValue().toFixed(2),
        },
        {
            accessorKey: 'BoundingBox',
            header: 'Bounding Box',
            size: 90,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'left' /*sx: { paddingRight: '50px' }*/ },
            Cell: ({ cell, row }) => {
                return <BoundingBoxPopover value={cell.getValue()} />;
            },
        },
    ];

    const columns = React.useMemo(() => memoizedColumns, []);

    React.useEffect(() => {
        refreshPackageTable();
        getStatus().then((res) => {
            let status = [];
            if (res && res.data) {
                res.data.forEach((d) => {
                    status.push({
                        label: d,
                        value: d,
                    });
                });
                setStatusOptions(status);
            }
        });
    }, []);

    const refreshPackageTable = () => {
        setLoading(true);
        getPackages().then(
            (res) => {
                let clients = [],
                    clientOptions = [],
                    vintage = [],
                    vintageOptions = [];
                setData(res.data);
                setFilteredData(res.data);

                res.data.forEach((d) => {
                    if (!clients.includes(d.ClientId)) {
                        clients.push(d.ClientId);
                        clientOptions.push({
                            label: d.ClientId,
                            value: d.ClientId,
                        });
                    }
                    if (!vintage.includes(d.Version)) {
                        vintage.push(d.Version);
                        vintageOptions.push({
                            label: d.Version,
                            value: d.Version,
                        });
                    }
                });

                clientOptions.sort((a, b) => a['value'].localeCompare(b['value'], 'en', { numeric: true }));
                vintageOptions.sort((a, b) => b['value'].localeCompare(a['value'], 'en', { numeric: true }));

                setClientOptions(clientOptions);
                setVintageOptions(vintageOptions);
                setLoading(false);
            },
            () => {
                setData([]);
                setFilteredData([]);
                setClientOptions([]);
                setVintageOptions([]);
                setLoading(false);
            },
        );
    };

    const handleFilterOpen = () => {
        setFilterOpen(true);
    };

    const handleFilterClose = (option) => {
        if (option === 'SAVE') {
            setFilterOpen(false);
            let d = [...data];
            if (clientFilter && clientFilter.value) {
                d = d.filter((a) => a.ClientId === clientFilter.value);
            }
            if (statusFilter && statusFilter.value) {
                d = d.filter((a) => a.Status === statusFilter.value);
            }
            if (vintageFilter && vintageFilter.value) {
                d = d.filter((a) => a.Version === vintageFilter.value);
            }

            if (startDate || endDate) {
                if (startDate) {
                    d = d.filter((a) => isAfter(parseISO(a.DateRequestReceived), startDate));
                }
                if (endDate) {
                    d = d.filter((a) => isBefore(parseISO(a.DateRequestReceived), endDate));
                }
            }

            setFilteredData(d);
        } else if (option === 'RESET') {
            setClientFilter(null);
            setStatusFilter(null);
            setVintageFilter(null);
            setStartDate('');
            setEndDate('');
        }
    };

    const isFiltered = () => {
        return (
            (clientFilter && clientFilter.value) ||
            (statusFilter && statusFilter.value) ||
            (vintageFilter && vintageFilter.value) ||
            startDate ||
            endDate
        );
    };
    return (
        <>
            <Grid
                container
                item
                xs={12}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ margin: '15px' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={filteredData}
                        positionPagination='both'
                        displayColumnDefOptions={{
                            'mrt-row-expand': {
                                muiTableHeadCellProps: {
                                    align: 'left',
                                },
                                muiTableBodyCellProps: {
                                    align: 'left',
                                },
                            },
                        }}
                        positionExpandColumn='last'
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableTopToolbar={true}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 15],
                        }}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            pagination: { pageSize: 10 },
                            sorting: [{ id: 'PackageId', desc: true }],
                        }}
                        state={{ isLoading: loading }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => refreshPackageTable()}
                                    title={'Refresh'}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => handleFilterOpen()}
                                    title={'Show Filters'}
                                >
                                    <FilterAltIcon style={isFiltered() ? { color: '#3c763d' } : {}} />
                                </IconButton>
                            </div>
                        )}
                    />
                </Grid>
            </Grid>

            <PackageFilterDialog
                filterOpen={filterOpen}
                handleFilterClose={handleFilterClose}
                clientOptions={clientOptions}
                statusOptions={statusOptions}
                vintageOptions={vintageOptions}
                clientFilter={clientFilter}
                setClientFilter={setClientFilter}
                vintageFilter={vintageFilter}
                setVintageFilter={setVintageFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        </>
    );
};

export default Package;
